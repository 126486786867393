import { useEffect, useContext } from 'react'

import { LoginContext } from '../../contexts/loginContext'

import LoadTranskey from '../../helpers/LoadTranskey'

import axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import LogRocket from 'logrocket'

async function checkToken () {

      let response = /*await axios.post(config.endpoint+'/api/v1/auth/refreshJWTToken', {}) */
                    { data: { data: { success: localStorage['wgc-res-api-token'] != null, token: localStorage['wgc-res-api-token'], user: { name: "User" } } } }

      return response.data
}

async function generatePin (email) {

    let resp = false

    let content = { username: email }

    try {  

      let response = await axios(config.endpoint+'/api/v1/login/requestPinCode', {      
        method: 'post',
        data: JSON.stringify(content)
      })

      let data = response.data

      if(data.success) {
        toast.success(LoadTranskey('pinSent')+"!")
        resp = true
      }

      if(!data.success) {
        toast.error(LoadTranskey('noPinSent')+"!")
        resp = false
      }

    } catch(e) {
      toast.error(LoadTranskey('error'))
      console.log(e)
    }

    return resp
}

async function validatePin (username, pinCode) {

    let resp = { success: false, user: null}

    let content = {
      username,
      pinCode
    }

    try {
      
      let response = await axios(config.endpoint+'/api/v1/login/', {      
        method: 'post',
        data: JSON.stringify(content)
      })
      
      if(response.data.success) {

        toast.success(LoadTranskey('authSuccess')+"!")

        localStorage['wgc-res-api-token'] = response.data.data.token

        LogRocket.identify(response.data.data.user.id, {
            email: response.data.data.user.email,
            name: response.data.data.user.name
        })

        resp = { success: true, user: response.data.data.user} 
      }

    } catch (e) {
      console.log(e)
      toast.error(LoadTranskey('authFailed')+"!")
    }

    return resp
}

function Login () {

    const { setLoggedIn, setUser } = useContext(LoginContext)

    useEffect(() => {

        checkToken().then((res) => {
        
            if(res.data.success) {
                localStorage['wgc-res-api-token'] = res.data.token
                setUser(res.data.user)
                setLoggedIn(true)
            } else {
                setLoggedIn(false)
            }
        }).catch((e) => {

            console.error(e)
        })
    // eslint-disable-next-line
    }, [])
    
    return null
}

export { Login, generatePin, validatePin }