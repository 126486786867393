import React, { useState, useEffect } from 'react'
import cloneDeep from 'lodash.clonedeep'
import LoadTranskey from '../../helpers/LoadTranskey'
import NumberFormat from 'react-number-format'
import InputMask from 'react-input-mask'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function Payment(props) {

    const [userData, setUserData] = useState(
        { 
            firstName: '', 
            lastName: '', 
            companyName: '',
            phone: '', 
            email: localStorage['wgc-res-email'],
            city: '',
            zip: '',
            state: '',
            street: '',
            streetNumber: '',
            tax: '',
            invoiceNeeded: false,
            policy: false
        }
    )

    const [invoiceNeeded, setInvoiceNeeded] = useState(false)

    useEffect(() => {
        if(localStorage['wgc-res-user-data'] != null) {
            try {
                setUserData(JSON.parse(localStorage['wgc-res-user-data']))
            } catch(e) {
                console.log(e)
            }
        }
    // eslint-disable-next-line
    }, [localStorage['wgc-res-user-data']])

    useEffect(() => {
        setUserData({...userData, 
            tax: "",
            companyName: "",
            zip: "",
            city: "",
            state: "",
            street: "",
            streetNumber: "",
            invoiceNeeded: invoiceNeeded,
        })
        // eslint-disable-next-line
    }, [invoiceNeeded])

    const handleFormChange = (field, e) => {

        let value = replaceAll(e.target.value, {'_': '', ' ': ''})

        if(field === "tax" && value.length === 8) {

            let companyData = {}

            try {
                axios(`https://ipanel.okeoke.io/api/v1/common/tax/${value}`).then(res => {
                    if(res.data.success) {
                        companyData = res.data.data
                        let newValues = {...userData, 
                            tax: value,
                            companyName: companyData.shortName,
                            zip: companyData.zip,
                            city: companyData.city,
                            state: "-",
                            street: companyData.street,
                            streetNumber: companyData.streetNumber
                        }
                        setUserData(newValues)
                        localStorage['wgc-res-user-data'] = JSON.stringify(newValues)
                    } else {
                        toast.error(LoadTranskey('wrongTax'))
                        localStorage['wgc-res-user-data'] = JSON.stringify({...userData, tax: ""})
                        setUserData({...userData, tax: ""})
                    }
                })
            } catch (e) {
                console.log(e)
            }       
        } else {
            let newValue = cloneDeep(userData)
            newValue[field] = field === "policy" ? e.target.checked : e.target.value
            localStorage['wgc-res-user-data'] = JSON.stringify(newValue)
            setUserData(newValue)
        }    
    }

    const replaceAll = (str, mapObj) => {
        if(str == null) return null
        let re = new RegExp(Object.keys(mapObj).join("|"),"gi")
    
        return str.replace(re, function(matched){
            return mapObj[matched.toLowerCase()]
        })
    }

    const parseItems = () => {
        
        let resp = null

        try {
            resp = JSON.parse(props.reservation)
        } catch(e) {
            console.log(e)
        }

        return resp
    }

    const renderReservationData = (reservation) => {

        if(reservation == null) return null

        let resp = null

        if(reservation.entitiesAllData != null) {

            try {
                
               resp = Object.values(reservation.entitiesAllData).map((ent, index) => { return(
                   <div key={index} className="text-sm"><span>{ent.seats}x</span>{ent.entity.entity.name[localStorage['selectedLanguage']]}</div>
               )}) 
            } catch(e) {
                console.log(e)
            }

        }

        if(reservation.eventName != null) resp = <div className="text-sm">{`${reservation.eventName} (${reservation.count}${LoadTranskey('person')})`}</div>

        return resp
    }

    const reservation = parseItems()

    return(
        <div className="sunbedres-payment-container flex flex-col" style={{ minHeight: "450px", overflow: "auto" }}>
            <div className="payment-user-data flex flex-wrap" style={{ minHeight: "340px" }}>
                <div className="mb-1 w-full p-1">
                    <label className="flex items-center justify-start">
                        <input type="checkbox" className="form-checkbox" name="policy" onChange={(e) => setInvoiceNeeded(e.target.checked)} checked={invoiceNeeded}/>
                        <span className="ml-2 underline text-xs">{LoadTranskey('invoiceNeeded')}</span>
                    </label>
                </div>
                {!invoiceNeeded && <><div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="lastName">
                        {LoadTranskey('lastName')}
                    </label>
                    <input 
                        type="text" 
                        name="lastName" 
                        placeholder={LoadTranskey('lastName')}
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.lastName} 
                        onChange={(e) => handleFormChange('lastName', e)} 
                        required
                    />
                </div>
                <div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="firstName">
                        {LoadTranskey('firstName')}
                    </label>
                    <input 
                        type="text" 
                        name="firstName" 
                        placeholder={LoadTranskey('firstName')}
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.firstName} 
                        onChange={(e) => handleFormChange('firstName', e)} 
                        required
                    />
                </div></>}
                {invoiceNeeded && <>
                <div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="tax">
                        {LoadTranskey('tax')}
                    </label>
                    <InputMask 
                        mask="99999999"
                        type="text" 
                        name="tax" 
                        placeholder={LoadTranskey('tax')}
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.tax} 
                        onChange={(e) => handleFormChange('tax', e)} 
                    />
                </div></>}
                <div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="email">
                        {LoadTranskey('email')}
                    </label>
                    <input 
                        type="text" 
                        name="email" 
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.email} disabled
                    />
                </div>
                <div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="phone">
                        {LoadTranskey('phone')}
                    </label>
                    <InputMask 
                        mask="+36999999999"
                        type="text" 
                        name="phone" 
                        placeholder={LoadTranskey('phone')}
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.phone} 
                        onChange={(e) => handleFormChange('phone', e)} 
                        required
                    />
                </div>
                {invoiceNeeded && 
                    <div className="mb-1 w-1/2 p-1">
                        <label className="block text-xs" htmlFor="zip">
                            {LoadTranskey('companyName')}
                        </label>
                        <input 
                            type="text" 
                            name="companyName" 
                            placeholder={LoadTranskey('companyName')}
                            className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                            defaultValue={userData.companyName} 
                            required
                            disabled
                        />
                    </div>
                }
                <div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="zip">
                        {LoadTranskey('zip')}
                    </label>
                    <InputMask 
                        mask="9999"
                        name="zip" 
                        placeholder={LoadTranskey('zip')}
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.zip} 
                        onChange={(e) => handleFormChange('zip', e)} 
                        required
                        disabled={invoiceNeeded}
                    />
                </div>
                <div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="city">
                        {LoadTranskey('city')}
                    </label>
                    <input 
                        type="text" 
                        name="city" 
                        placeholder={LoadTranskey('city')}
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.city} 
                        onChange={(e) => handleFormChange('city', e)} 
                        required
                        disabled={invoiceNeeded}
                    />
                </div>
                <div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="state">
                        {LoadTranskey('state')}
                    </label>
                    <input 
                        type="text" 
                        name="state" 
                        placeholder={LoadTranskey('state')}
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.state} 
                        onChange={(e) => handleFormChange('state', e)} 
                        required
                        disabled={invoiceNeeded}
                    />
                </div>
                <div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="street">
                        {LoadTranskey('street')}
                    </label>
                    <input 
                        type="text" 
                        name="street" 
                        placeholder={LoadTranskey('street')}
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.street} 
                        onChange={(e) => handleFormChange('street', e)} 
                        required
                        disabled={invoiceNeeded}
                    />
                </div>
                <div className="mb-1 w-1/2 p-1">
                    <label className="block text-xs" htmlFor="streetNumber">
                        {LoadTranskey('streetNumber')}
                    </label>
                    <input 
                        type="text" 
                        name="streetNumber" 
                        placeholder={LoadTranskey('streetNumber')}
                        className="form-input block rounded px-1 py-1 mt-1 w-full text-xs" 
                        value={userData.streetNumber} 
                        onChange={(e) => handleFormChange('streetNumber', e)} 
                        required
                        disabled={invoiceNeeded}
                    />
                </div>
                <div className="mb-1 w-full p-1">
                    <label className="flex items-center justify-end">
                        <input type="checkbox" className="form-checkbox" name="policy" onChange={(e) => handleFormChange('policy', e)} checked={userData.policy}/>
                        <span className="ml-2 underline text-xs"><a target="_blank" rel="noreferrer" href={process.env.PUBLIC_URL+'/assets/docs/policy.pdf'}>{LoadTranskey('policyCheck')}</a></span>
                    </label>
                </div>
            </div>
            <div className="summary-header-container flex">
                <div className="border-b border-blue-light flex-grow mb-3"></div>
                <div className="mx-3 text-blue">{LoadTranskey('summary')}</div>
                <div className="border-b border-blue-light flex-grow mb-3"></div>
            </div>
            <div className="summary-container border-b border-blue-light pb-1">
                <div className="flex">
                    <div className="w-1/2 text-right text-grey pr-1 text-sm">{LoadTranskey('location')}:</div>
                    <div className="w-1/2 text-left text-blue pl-1 text-sm">WGC</div>
                </div>
                <div className="flex">
                    <div className="w-1/2 text-right text-grey pr-1 text-sm">{LoadTranskey('date')}:</div>
                    <div className="w-1/2 text-left text-blue pl-1 text-sm">{props.date}</div>
                </div>
                <div className="flex">
                    <div className="w-1/2 text-right text-grey pr-1 text-sm">{LoadTranskey('reservation')}:</div>
                    <div className="w-1/2 text-left text-blue pl-1">{renderReservationData(reservation)}</div>
                </div>
                <div className="flex">
                    <div className="w-1/2 text-right text-grey pr-1 text-sm">{LoadTranskey('sumTotal')}:</div>
                    <div className="w-1/2 text-left text-blue pl-1 text-sm">
                        <NumberFormat 
                            value={reservation?.totalPrice} 
                            displayType={'text'} 
                            thousandSeparator={"."} 
                            decimalSeparator={","} 
                            suffix={` Ft`} 
                        />
                    </div>
                </div>
            </div>
            <div className="text-center p-2">
            </div>
            {/*<div className="h-16 p-2">
                <img src={`${process.env.PUBLIC_URL}/assets/img/simple.jpg`} alt="simple" className="invis appear h-full mx-auto" />
            </div>*/}
        </div>
    )
}