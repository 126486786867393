// - Order/OrderWhere.js

import React from 'react'

export default function Agreement(props) {

    const selectedLanguage = props.selectedLanguage != null ? props.selectedLanguage : 'hu'

    switch(selectedLanguage) {
        case 'hu':
            return (
                <div className="orderAgreement invis appear font-gotham-light text-xs text-center">
                    <p>
                        {`Tudomásul veszem, hogy a ${props.company} adatkezelő által a ${props.page} 
                        oldalon felhasználói adatbázisában tárolt alábbi személyes adataim átadásra kerülnek az OTP Mobil Kft. 
                        (1143 Budapest, Hungária körút 17-19.), 
                        mint adatfeldolgozó részére. Az adatkezelő által továbbított adatok köre az alábbi:
                        Vásárlás adatai (név, e-mail cím, telefonszám, számlázási cím adatok)
                        Az adatfeldolgozóáltal végzett adatfeldolgozási tevékenység jellege és célja a
                        SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:`}
                        <br/>                      
                        <a className="agreementLink" href="https://simplepay.hu/vasarlo-aff" target="_blank" rel="noreferrer">https://simplepay.hu/vasarlo-aff</a>
                        <br/><br/>
                    </p>
                    <p>Fizetést elindítva egyben elfogadod a nyilatkozatot.</p>
                </div>)
        case 'en':
            return (
                <div className="orderAgreement invis appear font-gotham-light text-xs text-center">
                    <p>
                        {`I acknowledge the following personal data stored in the user account of ${props.company} in the user database of
                        ${props.page} will be handed over to OTP Mobil Ltd. who is trusted as data processor. The data transferred by 
                        the data controller are the following: (name, e-mail, phone number, invoice address)
                        The nature and purpose of the data processing activity performed by the data processor in 
                        the SimplePay Privacy Policy can be found at the following link:`}
                        <br/>
                        <a className="agreementLink" href="https://simplepay.hu/vasarlo-aff" target="_blank" rel="noreferrer">https://simplepay.hu/vasarlo-aff</a>
                        <br/><br/>
                    </p>
                    <p></p>
                </div>)
        default: return null
    }
}
  