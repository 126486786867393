import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { FadeLoader } from 'react-spinners'
import { toast } from 'react-toastify'

import Agreement from './Agreement'

import LoadTranskey from '../../helpers/LoadTranskey'
import SetBooking from '../../helpers/SetBooking'
import SimplePayStart from '../../helpers/SimplePayStart'

function StartBooking(props) {

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [link, setLink] = useState(null)

  useEffect(() => {

    async function booking() {

      let bookingData = null
      let userData = null
      let res = false

      try {

        if(localStorage['wgc-res-sunbed-start-payment']) {
          bookingData = {...JSON.parse(localStorage['wgc-res-reserve-items'])}
        }

        if(localStorage['wgc-res-event-booking-start-payment']) {
          bookingData = {...JSON.parse(localStorage['wgc-res-selected-event-booking-data'])}
        }

        userData = {...JSON.parse(localStorage['wgc-res-user-data'])}
      } catch(e) {
        setError(true)
        console.log(e)
      }

      if(bookingData != null && userData != null) {

        console.log(bookingData, userData)

        let invoiceInfo = {
          name: (userData?.companyName != null && userData?.companyName !== "") ? userData?.companyName : userData?.lastName+" "+userData?.firstName,
          city: userData.city,
          countryCode: "HU",
          floor: "-",
          state: userData.state,
          street: userData.street,
          streetNumber: userData.streetNumber,
          zip: userData.zip,
          tax: userData.tax,
          taxType: userData.tax === "" ? "NO_TAX_NUMBER" : "HAS_TAX_NUMBER"
        }
  
        let params = {
          bookDate: bookingData.bookDate,
          bookDuration: 60,
          bookGuests: 1,
          bookName: userData.lastName+" "+userData.firstName,
          bookPhone: userData.phone,
          bookDescription: "",
          entities: Object.values(bookingData.entities),
          invoiceInfo
        }
        
        try {

          res = await SetBooking(params)
        } catch(e) {
          toast.error(LoadTranskey('error'))
          setError(true)
          console.log(e)
        }

        if(res) {

          if(localStorage['wgc-res-sunbed-start-payment']) deleteSunbedResData()
          if(localStorage['wgc-res-event-booking-start-payment']) deleteEventBookingData()
          
          localStorage['wgc-res-last-booking-id'] = res
        }

        if(!res) {
          
          toast.error(LoadTranskey('error'))
          setError(true)
        }
        
        setTimeout(() => {
          setLoading(false)
        },[300]) 
      }
    }

    (localStorage['wgc-res-last-booking-id'] == null && localStorage['wgc-res-booking-uuid'] == null) ? booking() : setLoading(false)
  }, [])

  useEffect(() => {

    async function simpleStart() {

      try {

        let bookingID = localStorage['wgc-res-last-booking-id']

        if(localStorage['wgc-res-booking-uuid'] != null) bookingID = localStorage['wgc-res-booking-uuid']

        let res = await SimplePayStart(bookingID, localStorage['selectedLanguage'])
      
        if(res.paymentUrl != null) setLink(res.paymentUrl)

        if(res.paymentUrl == null) setLink(false)

      } catch(e) {
        toast.error(LoadTranskey('error'))
        console.log(e)
      }
        
    }

    if(!loading && localStorage['wgc-res-last-booking-id'] != null) simpleStart()
    // eslint-disable-next-line
  }, [loading])

  const deleteSunbedResData = () => {

    localStorage.removeItem('wgc-res-sunbed-selected-date')
    localStorage.removeItem('wgc-res-selected-sunbed-step')
    localStorage.removeItem('wgc-res-reserve-items')
    localStorage.removeItem('wgc-res-sunbed-start-payment')
  }

  const deleteEventBookingData = () => {

    localStorage.removeItem('wgc-res-selected-event-date')
    localStorage.removeItem('wgc-res-selected-event-booking-step')
    localStorage.removeItem('wgc-res-selected-event-booking-data')
    localStorage.removeItem('wgc-res-event-booking-start-payment')
  }
    
  if(loading) return (
    <div className="w-full h-full bg-grey-dark opacity-70">
      <div className="w-full h-full flex flex-col items-center justify-center z-20">
          <div className="">Booking in progress</div>
          <FadeLoader color={'#fff'} />
      </div>
    </div>
  )

  if(error) return (
    <div className="centered-box absolute top-2/4 left-2/4 max-h-48 p-3 w-full max-w-lg bg-white z-60 flex flex-col lg:rounded-md justify-center items-center text-center">
      <div>{LoadTranskey('bookingFailed')+"!"}</div>
      <div className="btn" onClick={() => { window.location.reload() }}>{LoadTranskey('tryAgain')}</div>
      <div className="btn"onClick={() => { props.history.replace('/') }} >{LoadTranskey('back')}</div>
    </div>
  )

  return (
    <div className="centered-box absolute w-full lg:top-2/4 lg:left-2/4 lg:w-1/3 p-3">
      <div className="bg-white lg:rounded-md text-blue m-3 p-3 font-gotham text-xs text-center">
        {LoadTranskey('payMsg')}
      </div>
      <div className="bg-white lg:rounded-md text-blue m-3 p-3">
        <div className="text-2xl text-center mb-3">SimplePay</div>
        <Agreement 
          company={"Company"} 
          page={"webshop"} 
          selectedLanguage={localStorage['selectedLanguage']}
        />
        <a href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" download target="_blank" rel="noopener noreferrer">
          <img 
            className="simpleLogos mx-auto" 
            src={`${process.env.PUBLIC_URL}/assets/img/simple.jpg`} 
            alt="simpleLogos"
          />
        </a>
        {link == null && <div className=""><FadeLoader color={'#fff'} /></div>}
        {link != null && link && <div className="btn"><a href={link} >{`${LoadTranskey('pay')}`}</a></div>}
        {link != null && !link && <div className="btn"onClick={() => { window.location.reload() }} >{`${LoadTranskey('tryAgain')}`}</div>}
        {link != null && !link && <div className="btn"onClick={() => { props.history.replace('/') }} >{LoadTranskey('back')}</div>}
      </div>
    </div>
  )
}

export default withRouter(StartBooking)