import React, { useContext } from 'react'

import { LoginContext } from '../../contexts/loginContext'
import MainContext from '../../contexts/MainContext'

import NumberFormat from 'react-number-format'
import cloneDeep from 'lodash.clonedeep'
import moment from 'moment'
import { toast } from 'react-toastify'

import TempBooking from '../../helpers/TempBooking'
import LoadTranskey from '../../helpers/LoadTranskey'

import CountButton from '../../components/CountButton'

export default function EventField(props) {

    const { loggedIn } = useContext(LoginContext)
    const { setPinModalVisible } = useContext(MainContext)

    const handleClickOnCountButton = () => {
        if(!loggedIn) {
            setPinModalVisible(true)
        }
    }

    const handleChangeItemCount = async (params) => {


        if(loggedIn) {
    
            try {
                
                let hour = props.event.availability != null ? Object.keys(props.event.availability)[0] : "12"
                if(hour < 10) hour = "0"+hour
                let dateAffected = moment(props.event.entity.dateAffected).format("YYYY-MM-DD")
                let bookDate = moment(dateAffected + " " + hour + ":00").format("YYYY-MM-DD HH:mm")
                
                let bookingParams = {
                    entityID: props.event.entity.id,
                    bookDate,
                    bookDuration: 840,
                    seats: params.count
                }
    
                let resp = await TempBooking(bookingParams)

                if(!resp.success && resp.code === 401) {
                    setPinModalVisible(true)
                }

                if(resp.success) {

                    let newValues = cloneDeep(props.eventBookingData)

                    if(newValues.entities["entity_"+props.event.entity.id] == null) {
                        newValues.entities["entity_"+props.event.entity.id] = { id: props.event.entity.id, seats: 0 }
                    }
    
                    newValues.entities["entity_"+props.event.entity.id].seats += params.diff
                    newValues.entities["entity_"+props.event.entity.id].id = props.event.entity.id
                    newValues.count += params.diff
                    let tempPrice = params.diff * parseFloat(props.event.entity.seatPrice)
                    newValues.totalPrice += tempPrice
                    newValues.eventName = props.event.entity.name[localStorage['selectedLanguage']]
                    newValues.bookDate = moment(bookDate).format()

                    console.log("newValues", newValues)
        
                    localStorage['wgc-res-selected-event-booking-data'] = JSON.stringify(newValues)
                    props.setEventBookingData(newValues)   
                }
                
            } catch(e) {
                console.log(e)
                console.log("Event error", props.event)
                toast.error(LoadTranskey('error')+'!')
            }

        }
    }

    const checkUserLoggedIn = () => {
        return loggedIn
    }

    const addItem = () => {

        if(loggedIn) {

            let availableSeats = checkAvailableSeats()
        
            if(availableSeats === 0) {
                toast.error(LoadTranskey('eventNotAvailable')+'!')
                return null
            }
                
            handleChangeItemCount({ count: 1, diff: 1 })
            
        }

        if(!loggedIn) setPinModalVisible(true)
    }

    const checkAvailableSeats = () => {

        let availableSeats = 0
        let isOpen = false
        try {
            if(props.event.availability != null) {
                let availability = Object.values(props.event.availability)[0]
                availableSeats = availability.segments['00'].availableSeats
                isOpen = props.event.entity.firstOpenSegment != null
            }
        } catch(e) {
            console.log(e)
        }
        
        if(availableSeats > 0 && isOpen) return availableSeats
        
        return 0

    }

    let availableSeats = checkAvailableSeats()


    return(
        <div className="event-field p-2">
            <div className="selected-event-data-container w-full md:w-2/3 flex flex-col pt-0">
                <div className="event-name text-blue text-xl mb-2">{props.event.entity.name[localStorage['selectedLanguage']]}</div>
                <div className="event-description flex-grow text-xs font-gotham-light">{props.event.entity.description[localStorage['selectedLanguage']]}</div>
            </div>
            <div className="flex justify-between items-center text-xs py-1 border-b mt-4 mb-2 border-blue-light border-opacity-60">
                <div className="item-label w-2/4 pl-2 select-none">
                    <div className="item-name">{LoadTranskey('bookSeat')}</div>
                    <div className="item-unit">
                        (<span>
                            <NumberFormat 
                                value={parseFloat(props.event.entity.seatPrice)} 
                                displayType={'text'} 
                                thousandSeparator={"."} 
                                decimalSeparator={","} 
                                suffix={` Ft`} 
                            />
                        </span>/<span>{LoadTranskey('person')}</span>)
                    </div>
                </div>
                <div className="count-button w-1/4" onClick={handleClickOnCountButton}>
                    <CountButton 
                        onChange={handleChangeItemCount} 
                        count={props.eventBookingData.entities["entity_"+props.event.entity.id]?.seats}
                        addItem={addItem}
                        canSelect={checkUserLoggedIn}
                        maxValue={availableSeats > 10 ? 10 : availableSeats}
                    />
                </div>
                <div className="item-sum-price w-1/4 text-right pr-2 select-none">
                    <NumberFormat 
                        value={props.eventBookingData.entities["entity_"+props.event.entity.id]?.seats * parseFloat(props.event.entity.seatPrice)} 
                        displayType={'text'} 
                        thousandSeparator={"."} 
                        decimalSeparator={","} 
                        suffix={` Ft`} 
                    />
                </div>
            </div>
        </div>
    )
}