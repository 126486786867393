import React, { useContext } from 'react'
import MainContext from '../../contexts/MainContext'

import LoadTranskey from '../../helpers/LoadTranskey'

export default function Header(props) {

    const { selectedLanguage, changeLanguage } = useContext(MainContext)

    return(
        <div className="sunbedres-header-container select-none relative w-screen flex flex-col lg:flex-row p-2 lg:p-0 lg:pt-4 lg:pb-0 max-w-screen-lg mx-auto">
            <div className="image-container flex lg:w-1/3">
                <div className="w-16 h-16 lg:w-1/2 lg:pr-1 mr-2 lg:mr-0 lg:h-auto lg:pb-2">
                    <a href="https://wgc2022.hu/"><img className="invis appear image logo-image w-16 h-16 lg:w-full lg:h-full object-cover lg:mr-2 lg:mb-0" src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="logo" /></a>
                </div>
                <div className="w-16 h-16 lg:w-1/2 lg:pl-1 mr-2 lg:mr-0 lg:h-auto lg:mb-2 bg-blue">
                    <img className="invis appear image sunbed-image w-16 h-16 lg:w-full lg:h-full object-cover lg:mr-2 lg:mb-0" src={`${process.env.PUBLIC_URL}/assets/img/${props.img}`} alt="logo" />
                </div>
            </div>
            <div className="title-lang-container flex-grow flex-col justify-between lg:w-2/3 lg:pb-1">
                <div className="language-select-container absolute top-2 right-2 lg:relative flex justify-end">
                    <div className={`language-container cursor-pointer select-none language-hu m-1 py-1 px-3 text-xs ${selectedLanguage === 'hu' ? 'bg-blue' : ''}`} onClick={() => { changeLanguage('hu') }}>
                        <p className={`language-text font-bold ${selectedLanguage === 'hu' ? 'text-white' : 'text-grey'}`}>Hu</p>
                    </div>
                    <div className={`language-container cursor-pointer select-none language-hu m-1 py-1 px-3 text-xs ${selectedLanguage === 'en' ? 'bg-blue' : ''}`} onClick={() => { changeLanguage('en') }}>
                        <p className={`language-text font-bold ${selectedLanguage === 'en' ? 'text-white' : 'text-grey'}`}>En</p>
                    </div>
                </div>
                <div className="header-title text-white font-bold text-2xl lg:text-3xl font-gotham lg:pl-1">{LoadTranskey(props.headerTitleKey)}</div>
            </div>
        </div>
    )
}