import packageJson from '../package.json'

const prod = false

/** Dev config **/
let config = {
    prod: false,
    version: packageJson.version,
    endpoint: "https://bookingdev.okeoke.io",
    apiBaseUrl: "https://bookingdev.okeoke.io/",
    backRef: "https://wgcdev.okeoke.io/bookingview/simplePay",
    basename: "/",
    layoutID: 2
}

/** Prod config  **/
if(prod) {
    config = {
        prod: true,
        version: packageJson.version,
        endpoint: "https://booking.okeoke.io",
        apiBaseUrl: "https://booking.okeoke.io/",
        backRef: "https://",
        basename: "/",
        layoutID: 2
    }
}

export default config