import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import moment from 'moment'
import LoadTranskey from '../../helpers/LoadTranskey'

export default function SunbedResCalendar(props) {

    const [value, onChange] = useState(new Date())

    useEffect(() => {
        if(localStorage['wgc-res-sunbed-selected-date'] != null) {
            onChange(new Date(localStorage['wgc-res-sunbed-selected-date']))
        }
    // eslint-disable-next-line
    }, [localStorage['wgc-res-sunbed-selected-date']])

    const onChangeDate = (e) => {
        let formattedDate = moment(e).format("YYYY-MM-DD")
        onChange(e)
        props.onSelectDate(formattedDate)
    }

    let minDate = new Date()
    minDate.setDate(minDate.getDate() + 1)

    return(
        <div className="sunbedres-calendar-container lg:max-w-sm lg:mx-auto flex-grow">
            <Calendar
                onChange={onChangeDate}
                value={value}
                locale={localStorage['selectedLanguage']}
                next2Label={null}
                prev2Label={null}
                minDate={minDate}
            />
            <p className="mt-3">{LoadTranskey("calendarInfo")}</p>
        </div>
    )
}